body,html {
  height: 100%;
  margin: 0;
}

.header-nav {
  width: 100%;
  height: 65px;
  z-index: 5;
  top: 0px;
  left: 0px;
  padding: 0px;
  background-color: rgba(255,255,255,0);

}

.header-bar {
  height: 27px;
  background-color: hsla(5, 85%, 36%, 1);
  background: hsla(5, 85%, 36%, 1);
  border-top: 7px solid #eeba2f;
}

.header-links {
  height: 25px;
	display: table;
	width: 100%;
}

.header-rainbow {
  height: 5px;
  background: -webkit-linear-gradient(90deg, #019122 10%, #d40902 10%, #d40902 20%, #edeb00 20%, #edeb00 30%, #FFF 30%, #FFF 40%, #02267e 40%, #02267e 50%, #019122 50%, #019122 60%, #d40902 60%, #d40902 70%, #edeb00 70%, #edeb00 80%, #FFF 80%, #FFF 90%, #02267e 90%, #02267e 100% );
  background: -moz-linear-gradient(90deg, #019122 10%, #d40902 10%, #d40902 20%, #edeb00 20%, #edeb00 30%, #FFF 30%, #FFF 40%, #02267e 40%, #02267e 50%, #019122 50%, #019122 60%, #d40902 60%, #d40902 70%, #edeb00 70%, #edeb00 80%, #FFF 80%, #FFF 90%, #02267e 90%, #02267e 100% );
  background: linear-gradient(90deg, #019122 10%, #d40902 10%, #d40902 20%, #edeb00 20%, #edeb00 30%, #FFF 30%, #FFF 40%, #02267e 40%, #02267e 50%, #019122 50%, #019122 60%, #d40902 60%, #d40902 70%, #edeb00 70%, #edeb00 80%, #FFF 80%, #FFF 90%, #02267e 90%, #02267e 100% );
  border-bottom: 1px solid #eee;
}


.title {
  font-family: 'Arapey', serif;
  font-size: 40px;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  margin-left: 150px;
  line-height: 70%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.title2 {
  font-family: 'Arapey', serif;
  font-size: 40px;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  margin-right: 40px;
}

.red {
  color: hsla(5, 85%, 36%, 1);
}

.orange {
  color: #eeba2f;
}

@media (max-width: 780px) {
  .title {
    font-family: 'Arapey', serif;
    font-size: 30px;
    font-weight: 400;
    color: hsla(5, 85%, 36%, 1);
    text-rendering: optimizeLegibility;
    margin-right: 20px;
  }
}

.logo {
  position: absolute;
  top: 0;
  left: 35px;
  width: 100px;
  height: 130px;
  z-index: 10;
  background-image: url('/okl_logo.gif');
  background-size: 100px;
}

.login {
  width: 60%;
  height: 60%;
  margin: 0px auto;
}

.link {
  height: 25px;
  width: 15%;
  text-align: center;
  float: right;
}

.v-fixed {
  height: 100%;
}

.body {
    width: 100%;
    position: relative;
}

.grid {
    position: relative;
    padding-left: 160px;
    margin: 0px auto;
    max-width: 85%;
    overflow: hidden;
    background: white;
    z-index: 2;
}

.nav {
  position: absolute;
  width: 160px;
  padding-top: 10px;
  padding: 5px;
}

.mobile-link {
  display: none;
}

.mobileShow { display: none;}

@media (max-width: 780px) {
  .nav-links {
    display: none;
    overflow: hidden;
  }
  .link {
    display: block;
    float: none;
    width: 100%;
  }
  .mobile-link {
    display: block;
    float: right;
    padding-right: 10px;
    padding-top: 2px;
    width: 25px;
    cursor: pointer;
  }
  .mobile-wrapper {
    width: 80%;
    margin: 0px auto;
  }
  .mobileShow { display: block;}
}

.nav-links {
  float: right;
  height: 25px;
  width: 95%;
}

.link a {
  color: #222;
  text-decoration: none;
}

.link a:hover {
  color: hsla(5, 85%, 36%, 1);
}

.mobile-link a {
  font-size: 16px;
  color: hsla(5, 85%, 36%, 1);
  text-decoration: none;
}

.mobile-link a:hover {
  color: hsla(5, 85%, 36%, 1);
}

.black {
  font-color: black;
  color: black;
  text-decoration: none;
}

.blue {
  color: #02267e;
}

.green {
  color: #019122;
}


.last {
	border-right: none;
}

.first {
  border-left: 1px solid hsla(5, 85%, 36%, 1);
}

table {
  border-collapse: collapse;
  border-color: none;
  /*table-layout: fixed;*/
  width: 100%;
}

.medium {
  width: 200px;
}

.large {
  width: 350px;
}

.library {
  margin: 0px auto;
  padding-left: 40px;
}

.library th {
  border-bottom: 1px solid black;
}

.library thead tr {

}

.library tr:nth-child(2n) {
  background-color: #eee;
}

.library .download {
  background-image: image-url('circle-arrow-down.png');
  background-size: 25px;
  width: 25px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  display: inline-block;

}

.top-padded {
  padding-top: 25px;
}

.field .select-multiple {
  height: 100px;
}

.field_with_errors {
  padding: 2px;
  background-color: red;
  display: table;
}

.right {
  float: right;
}

.button.solid {
  background-color: #eee;
  border: 1px solid black;
}

.white {
  color: white;
}

.right {
  float: right;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

label {
  min-width: 150px;
}

.header-right {
    float: right;
}

.header-name {
  background-color: #fff;
  height: 25px;
  padding: 0px 10px;
  font-size: 14px;
  margin-right: 15px;
  color: black;
}

.header-drop {
  background-image: image-url("down-button.png");
  background-size: 9px 9px;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  width: 9px;
  height: 9px;
  float: right;
  margin-top: 8px;
  margin-left: 5px;
}

.center-post {
  width: 80%;
  margin: 0px auto;
  margin-top: 25px;
}

.center-text {
  text-align: center;
}

img.center {
  display: block;
  margin: 0 auto;
}

.error {
  color: red;
  margin-right: 5px;
}

.error_message {
  color: red;
}

#error_explanation h2 {
  text-align: left;
  font-weight: bold;
  padding: 5px 5px 5px 15px;
  font-size: 12px;
  margin: -7px;
  margin-bottom: 0px;
  background-color: #c00;
  color: #fff;
}

#error_explanation ul li {
  font-size: 12px;
  list-style: square;
}

.g-recaptcha {
  padding-bottom: 10px;
}

.home-button {
  position: fixed;
  right: 20px;
  top: 20px;
  z-index: 10;
  width: 50px;
  height: 25px;
  text-align: center;
  background-color: white;
  padding: 3px;
  border: 1px solid black;
  border-radius: 10px;
}

.black-link {
  color: black;
}

.black-link:hover {
  color: #1EAEDB;
 }

.no-margin {
  margin: 0px;
}
